<template>
  <div>
    <q-list
      class="rounded"
      bordered
      separator
    >
      <q-item
        v-for="item in data"
        :key="item.id"
        v-ripple
        clickable
        class="row justify-center items-center"
        style="flex-wrap: wrap !important;"
        :class="getClassName(item)"
        @dblclick="handleClick(item)"
      >
        <q-item-section class="col-12 col-sm-4">
          <place-object
            v-if="item._embedded && item._embedded.place"
            :data="item._embedded.place"
          />
        </q-item-section>

        <q-item-section
          class="col-3 col-sm-2 q-pt-sm row justify-center items-center"
          @click="handleImageClick(item._embedded.orderProduct.productOfferRaw)"
        >
          <img
            style="height: 80px; width: 80px; object-fit: contain;"
            :src="item._embedded.orderProduct.productOfferRaw.image || fallbackImage"
            :alt="item._embedded.orderProduct.productOfferRaw.name"
            @error="onImageLoadFailure"
          >
        </q-item-section>

        <q-item-section class="col-4 col-sm-4 q-pt-sm">
          <div>
            {{ item._embedded.orderProduct.productOfferRaw.name }} <span>({{ item._embedded.orderProduct.productOfferRaw.id }})</span>
          </div>

          <div>
            {{ getBarcode(item).join(', ') }}
          </div>
        </q-item-section>

        <q-item-section class="col-4 col-sm-1 q-pt-sm text-center">
          <strong>{{ item.scannedCount + '/' + item._embedded.orderProduct.count }}</strong>
        </q-item-section>
      </q-item>
    </q-list>

    <q-dialog
      v-model="isOpenImage"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
      @click="handleImage"
    >
      <q-card
        class="row items-center justify-center"
        style="background: rgba(0, 0, 0, .7);"
      >
        <img
          style="height: 90vh; width: 90vw; object-fit: contain;"
          :src="image || fallbackImage"
          @error="onImageLoadFailure"
        >
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'DynamicTask',
  emits: ['click'],
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpenImage: false,
      image: null,
      fallbackImage: 'assets/img/fallback-image/package.png',
      columns: [
        { label: this.$t('Place'), name: 'place', align: 'center' },
        { label: this.$t('Image'), name: 'image', align: 'center' },
        { label: this.$t('Offer'), name: 'offer', align: 'left' },
        { label: this.$t('Quantity'), name: 'quantity', align: 'center' }
      ]
    }
  },
  methods: {
    getClassName (offer) {
      if (offer.scannedCount > 0 && offer.scannedCount < offer._embedded.orderProduct.count) {
        return 'bg-warning'
      }

      if (offer.scannedCount >= offer._embedded.orderProduct.count) {
        return 'bg-positive'
      }

      return ''
    },
    handleImageClick (offer) {
      this.image = offer.image || this.fallbackImage
      this.isOpenImage = true
    },
    handleImage () {
      this.isOpenImage = false
      this.image = null
    },
    getBarcode (product) {
      return (product.instructions.find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode
    },
    handleClick (data) {
      const item = {
        type: this.rawData.type,
        event: this.rawData.event,
        data
      }

      this.$emit('click', item)
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    getColor (item) {
      if (item.scannedCount === 0) {
        return 'danger'
      }

      if (item.scannedCount === item.count) {
        return 'green'
      }

      return 'yellow'
    }
  }
}
</script>
